import React, { useState } from "react";
import cx from "classnames";
import Icon from "@elevio/styles/lib/components/icons";
import SearchInput from "./SearchInput";
import { Logo } from "./Logo";

type Props = {
  className?: string;
  hideSearch?: boolean;
};

const Header: React.FC<Props> = ({ children, className, hideSearch }) => {
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  return (
    <header className={cx("bg-white", className)}>
      <div className="w-full lg:max-w-6xl mx-auto p-6">
        <nav className="flex items-center justify-between">
          <div className="flex items-center flex-shrink-0 text-white mr-6">
            <Logo className="mr-6" />
          </div>

          <div className="items-center hidden md:flex">
            <a href="http://www.hartfordcity.net/" className="text-black">
              Hartford City, IN
            </a>
          </div>

          <div className="items-center flex md:hidden">
            {!hideSearch && (
              <button
                className="mr-6"
                onClick={() => setDisplaySearchBar(!displaySearchBar)}
              >
                <Icon icon="search" className="text-black" />
              </button>
            )}
            <button onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
              <Icon icon="menu" className="text-black" />
            </button>
          </div>
        </nav>
      </div>

      {displayMobileMenu && (
        <div className="py-12 pb-6 max-w-3xl mx-auto">
          <div className="flex flex-col items-center text-center">
            <a href="http://www.hartfordcity.net/" className="text-black">
              Hartford City, IN
            </a>
          </div>
        </div>
      )}

      {displaySearchBar && !hideSearch && (
        <div className="p-6 max-w-3xl mx-auto">
          <SearchInput />
        </div>
      )}
      {children && (
        <div className="bg-no-repeat bg-cover p-6">
          <div className="pt-6 pb-12 max-w-3xl mx-auto">{children}</div>
        </div>
      )}
    </header>
  );
};

export default Header;
