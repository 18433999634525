import * as React from "react";
import StyledSearchInput from "@elevio/styles/lib/components/Search";
import useSearch from "@elevio/kb-kit/lib/hooks/useSearch";

type Props = JSX.IntrinsicElements["input"];

const SearchInput: React.FC<Props> = (props) => {
  const { value, onChange, onKeyUp } = useSearch();

  return (
    <StyledSearchInput
      {...props}
      value={value}
      onKeyUp={onKeyUp}
      onChange={onChange}
      className="border-2"
    />
  );
};

export default SearchInput;
